.specialProjectContainer {
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;

}

.projectSection {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #ffffff; 
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); 
}

.projectSection h2, .projectSection h4 {
  color: #333; 
  margin-top: 0;
}

.projectSection p, .projectSection li {
  color: #666; 
  line-height: 1.6; 
}

.projectSection a {
  color: #313131;
  text-decoration: none;
}

.projectSection a:hover {
  text-decoration: underline; 
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

}

img {
  border-radius: 8px; 
}

ul, ol {
  padding-left: 20px; 
}

.alignLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
}

.alignRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: right;
}

.imgGif {
  align-self: flex-end;       
  height: 450px;
  width: auto;
  margin-right: 2em;
}

@media (max-width: 768px) {
  .container {
    padding: 20px;
 
    max-width: 90%; 
  }

  img {
    width: 90%; 
    height: auto;
    max-width: 550px; 
  }

  h2, h3 {
    text-align: center; 
    font-size: 1.5em;
  }

  .imgGif {
    align-self: flex-end;       
    height: 150px;
    width: 100%;
    margin-right: 1em;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  
  }

  .iframe {
    height: 100%;
  }
}