.technologies {
    display: flex;
    flex-direction: row; 
    flex-wrap: wrap; 
  }

  .technologies > div {
    background-color: #f0f0f0;
    color: #333; 
    padding: 2px 12px; 
    margin: 0 5px 5px 0; 
    border-radius: 5px; 
    font-size: 0.85em; 
  }
