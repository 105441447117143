
  .projectTileInner {
    transition: transform 0.8s;
    transform-style: preserve-3d;
    background-color: rgb(223, 223, 223);
    width: 25em;
    height: 15em; 
  }
  
  .projectTile.flipped .projectTileInner {
    transform: rotateY(180deg);
    
  }
  
  .projectTileFront, .projectTileBack {
    backface-visibility: hidden;
    position: absolute;
    box-shadow:  0 4px 8px rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
  }

    
  .projectTileFront img {
    width: 100%;
    height: 100%;
   object-fit: cover;
   object-position: left;

  }
  
  .projectTileBack {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    transform: rotateY(180deg);
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #333; 
    text-align: left;
    font-family: 'Arial', sans-serif; 
}


.projectTileBack h2 {
    margin: 0 0 10px 0; 
    color: #4b454e; 
    font-size: 1.5em; 
}


.projectTileBack > div {
    font-size: 0.85em; 
    color: #717171; 
    margin-bottom: 15px; 
}


.projectTileBack p {
    font-size: 1em; 
    margin: 0 0 20px 0; 
    line-height: 1.4; 
}


.technologies > div {
    display: inline-block; 
    background-color: #f0f0f0;
    color: #333; 
    padding: 5px 10px; 
    margin: 0 5px 5px 0; 
    border-radius: 5px; 
    font-size: 0.85em; 
}


.link {
    align-self: center; 
    color: #4b454e;
    text-decoration: none;
    padding: 10px 15px; 
    margin-top: auto; 
    border: 1px solid #4b454e; 
    border-radius: 4px; 
    transition: all 0.3s ease; 
}

.link:hover {
    background-color: #4b454e; 
    color: #ffffff; 
}

@media (max-width: 768px) {
  .container {
    padding: 20px;
    gap: 15px;
    max-width: 90%;
  }

  img {
    width: 50%;
    height: auto; 
    max-width: 650px;
  }

  h2, h3 {
    text-align: center; 
    font-size: 1.5em;
  }

  .link {
    padding: 8px 10px; 
    font-size: 0.8em; 
  }

  .technologies {
    padding: 2px 8px;
    font-size: 0.8em; 
    margin: 0 4px 4px 0;
  }
}