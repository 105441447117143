.slideshowContainer {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .container {
    margin: 50px 20px;
    padding: 10px;
    border-radius: 8px;
    background-color: #ffffff; 
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); 
  }
  
  .post {
    width: 100%; 
    height: 100%;
    padding: 0;
  }

  .buttonContainer  {
    display: flex;
    justify-content: space-between;
  }

  button {
    background-color: #ebe9eb;
    border: 2px solid #dfdddf;
    color: rgb(59, 59, 59);
    border-radius: 10px;
    padding: 10px 20px;
    margin: 0  10px;
    cursor: pointer;
    box-shadow:  0 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  @media (max-width: 768px) {
    .post {
      width: 100%; 
      height: 100%;
  
    }
    .slideshowContainer {
    flex-direction: column;
    padding-left: 30px;
    }
  
    
  .buttonContainer  {
    justify-content: flex-start;
  }

  }