.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: '100vh';
  position: absolute;
}

.floatingSprite {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  
}

.mainContent {
  margin:  0 30vh; 
  flex-grow: 1;
  z-index: 10;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #1d1f24;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@media (max-width: 768px) {
  .mainContent {
    margin: 0 20px;
    flex-direction: column;
    margin: 0 10px; 
    width: auto;
  }

  .App-header {
    min-height: 60vh;
    font-size: calc(8px + 2vmin);
  }

  h1, h2, h3 {
    font-size: 4vw; 
  }


  img {
    max-width: 100%;
    height: 100%;
  }
}
