.container {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .labelContainer {
    position: absolute;
    top: 80px;
    left: 20px;
    z-index: 1000;
  }
  
  .label {
    color: #333;
    margin-right: 10px;
    font-size: 1vh;

  }
  
  .input {
    background: grey;
    cursor: pointer;
    accent-color: grey;
  }

  @media (max-width: 768px) {
    .label, .input, .labelContainer {
      display: none;
    }
  }