.dashboard {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-evenly;
    padding: 20px;
    font-family: Arial, sans-serif;
    
  }

  .container { 
    padding: 20px;
    margin: 100px 20px;
    border-radius: 8px;
    background-color: #ffffff; 
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); 
  }

  .dropdown {
    color: #333333; 
    font-size: 1rem; 
    cursor: pointer; 
    background-color: #f0f0f0;
    border: 1px solid #cfcdcd;
    border-radius: 10px;
    padding: 5px 20px;
    margin: 10px;
    cursor: pointer;
    box-shadow:  0 2px 2px rgba(0, 0, 0, 0.5);
  }

  .dropdown:hover,
.dropdown:focus {
  outline: none;
  box-shadow:  0 2px 4px rgba(0, 0, 0, 0.5);
}

.label {
  margin-bottom: 5px; 
  color: #717171; 
  font-size: 1rem; 
  font-family: Arial, sans-serif; 
}

@media (max-width: 768px) {
  .dashboard {
    display: flex;
    flex-direction: column;
    
  }
  .controls {
    display: flex;
    flex-direction: column;
   
  }
.label {
  display: flex;
  justify-content: flex-start;
  padding: 4px 6px;
}
  .container { 
    padding: 10px 0;
    margin: 0 auto; 
    border-radius: 8px;
    background-color: #ffffff; 
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); 
    width: 100%; 
    box-sizing: border-box;
  }

  .dropdown {
    font-size: 1rem; 
    cursor: pointer; 
    border-radius: 10px;
    padding: 5px 10px;
    margin: 10px;
    width: 30%;

  }
  

}