.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas: 
    "photo about links"
    "skills skills skills"; 
  margin: 200px auto;
  max-width: 800px;
  height: auto; 
}
  
  .profileImage {
    grid-area: photo; 
    border-radius: 10px;
    margin-right: 20px;
    transition: transform 0.3s ease-in-out;
    width: 100%;
    height: auto;
  }
  
  .profileImage:hover {
    transform: scale(1.1);
  }
  
  .about {
    grid-area: about;
    display: flex;
    margin: 20px;
    justify-content: flex-start;
  }
  
  .aboutText {
    text-align: left;
    font-size: 24px;
    color: #333;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
  
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fadeInUp {
    animation: fadeInUp 0.8s ease-out;
  }

 
.links {
  grid-area: links;
    margin: 50px;
  }
  
  .links a {
    text-decoration: none;
    transition: color 0.3s ease;
    display: inline-block; 
    background-color: #929292;
    color: #ffffff; 
    padding: 5px 10px; 
    margin: 0 5px 5px 0; 
    border-radius: 5px; 
    font-size: 0.85em; 
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .links a:hover {
    color: #dddddd; 
    text-decoration: underline;
    box-shadow: 0 4px 8px rgba(0,0,0,0.6);
  }
  .skills {
    grid-area: skills; 
    display: flex;
    justify-content: center; 

 
  }

  @media (max-width: 768px) {
    .container {
      grid-template-columns: 1fr;
      grid-template-areas:
        "photo"
        "about"
        "links"
        "skills";
      margin: 100px 20px 20px 20px;
      max-width: 95%; 
    }
  
    .profileImage {
      width: 40%;
      margin: 10px auto;
    }
  
    .links {
      padding: 0px; 
    }
  
    .aboutText {
      font-size: 18px; 
    }
  
    .links a{
      padding: 8px 12px; 
      font-size: 14px; 
      margin: 5px 5px 5px 0;
    }
  
  }