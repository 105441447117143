.header {
    display: flex;
    justify-content: flex-start; 
    align-items: center; 
    background: grey;
    position: fixed;
    top: 0;
    height: 60px;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2); 
  }
  
  .headerLink {
    color: white; 
    font-weight: bold; 
    text-decoration: none; 
    padding: 10px 20px; 
    border-radius: 5px; 
    transition: background-color 0.3s, color 0.3s; 
  }
  
  .headerLink:hover {
    text-shadow:  2px 2px 4px rgba(0, 0, 0, 0.5);

  }

  @media (max-width: 768px) {
    .header {
        height: auto; 
        padding-top: 20px; 
        padding-bottom: 20px; 
    }

    .headerLink {
        padding: 8px 10px; 
        margin: 5px 0; 
    }
}