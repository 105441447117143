body {
  margin: 0 ;
 
  font-family: Arial, sans-serif;
  color: #120e16; 
  background-color: #F5F3F4; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1, h2, h3 {
  font-family: Arial, sans-serif;
  color: #1e1927;
  text-shadow:  1px 1px 2px rgba(0, 0, 0, 0.2);

}
