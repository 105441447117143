.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 10px;
  margin: 20px auto;
  max-width: 800px;
  height: auto; 
  padding: 40px; 
  gap: 20px;
}

h2 {
  margin-top: 0;
  color: #333; 
  font-size: 2em;
}

h3 {
  margin-top: 10px;
  color: #555;
  font-size: 1.5em;
}

.link {
  color: #838383; 
  text-decoration: none; 
  padding: 10px 15px; 
  border-radius: 5px; 
  background-color: #f0f0f0; 
  transition: background-color 0.3s ease, color 0.3s ease; 
}

.link:hover {
  background-color: #5f5f5f; 
  color: #ffffff; 
  text-decoration: none;
}


@media (max-width: 768px) {
  .container {
    padding: 20px;
    gap: 15px;
    max-width: 80%; 
  }

  img {
    width: 100%; 
    height: auto; 
    max-width: 650px;
  }
  
  .link {
    padding: 8px 10px; 
    font-size: 0.8em;
  }

}